import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environment'
import { BehaviorSubject, Subject, Observable } from 'rxjs'


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  private messageParams = new BehaviorSubject<Object>({});
  private showErrorPopup = new BehaviorSubject<boolean>(false);
  private showRefreshPopup = new BehaviorSubject<boolean>(false);
  private failedUpdation = new BehaviorSubject<boolean>(false);

  getQuestions(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/getMessages`, options);
  }

  setData(data: Object) {
    this.messageParams.next(data);
  }

  getData(): Observable<Object> {
    return this.messageParams.asObservable();
  }

  setErrorPopup(flag: boolean) {
    this.showErrorPopup.next(flag);
  }

  getErrorPopup(): Observable<boolean> {
    return this.showErrorPopup.asObservable();
  }

  setRefreshPopup(flag: boolean) {
    this.showRefreshPopup.next(flag);
  }

  getRefreshPopup(): Observable<boolean> {
    return this.showRefreshPopup.asObservable();
  }

  setFailedStatus(editStatus: any) {
    this.failedUpdation.next(editStatus);
  }

  getFailedStatus(): Observable<boolean> {
    return this.failedUpdation.asObservable();
  }

  getConfidentialInfo(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/getConfidentialInfo`, options);
  }

  getConfidentialDuration(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/getConfidentialityDuration`, options);
  }

  getInstitution(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/getInstitution`, options);
  }

  generateNda(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/generateNda`, options);
  }

  downloadNda(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/downloadNda`, options, {
      headers: {
        "Content-Type": "application/pdf",
      }
    });
  }

  saveResponses(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/storeResponses`, options);
  }

  saveTranscripts(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/storeTranscripts`, options);
  }

  getTranscripts(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/getTranscripts`, options);
  }

  updateTranscripts(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/updateTranscript`, options);
  }

  getPaymentStatus(options: Object) {
    return this.http.post(`${environment.apiUrl}/v1/getPaymentStatus`, options);
  }
}

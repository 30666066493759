import { Component, HostListener } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isMenuOpen: boolean = false;

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
      if(this.isMenuOpen){
        this.isMenuOpen = false;
      }
  }

  toggleMenu(event: any) {
    this.isMenuOpen = !this.isMenuOpen;
    event.stopPropagation();
  }
}
